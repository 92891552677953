<template>
  <LinkResolver :id="id" :link="url" class="sb-blog-ad" data-test-id="blog-ad">
    <ImageLazy
      :key="isSm"
      :src="adImage"
      bg-size="contain"
      :background-image="true"
      :quality="80"
      :width="isSm ? '720px' : '2880px'"
      class-variant="sb-blog-ad-image"
    >
      <div v-if="richTitle || richSubtitle" class="sb-blog-ad-link" data-test-id="sb-blog-ad-link">
        <div class="sb-blog-ad-link-content" data-test-id="sb-blog-ad-content">
          <div
            v-if="richTitle"
            class="sb-blog-ad-link-content-title"
            data-test-id="sb-blog-ad-title"
            v-html="richTitle"
          />
          <div
            v-if="richSubtitle"
            class="sb-blog-ad-link-content-subtitle"
            data-test-id="sb-blog-ad-subtitle"
            v-html="richSubtitle"
          />
        </div>
      </div>
    </ImageLazy>
  </LinkResolver>
</template>

<script setup>
import LinkResolver from '@/components/LinkResolver/LinkResolver'
const props = defineProps({
  id: { type: String, default: null },
  url: { type: String, default: null },
  title: { type: [String, Object], default: null },
  // eslint-disable-next-line vue/prop-name-casing
  image_mobile_url: { type: String, default: null },
  // eslint-disable-next-line vue/prop-name-casing
  image_mobile: { type: Object, default: () => {} },
  // eslint-disable-next-line vue/prop-name-casing
  image_url: { type: String, default: null },
  image: { type: Object, default: () => {} },
  subtitle: { type: [String, Object], default: null },
  hidden: { type: Boolean, default: false },
  lazy: { type: Boolean, default: true },
})

provide('lazy', props.lazy)

const { isSm } = useDeviceSize()

const richTitle = computed(() => {
  // Richtext if object from SB, else string
  if (props.title) {
    if (typeof props.title === 'object') {
      return renderRichText(props.title)
    } else if (typeof props.title === 'string') {
      return props.title
    }
  }
  return null
})

const richSubtitle = computed(() => {
  // Richtext if object from SB, else string
  if (props.subtitle) {
    if (typeof props.subtitle === 'object') {
      return renderRichText(props.subtitle)
    } else if (typeof props.subtitle === 'string') {
      return props.subtitle
    }
  }
  return null
})

const adImage = computed(() => {
  if (isSm.value && props.image_mobile_url) {
    return props.image_mobile_url
  } else if (isSm.value && props.image_mobile?.filename) {
    return props.image_mobile?.filename
  } else if (props.image_url) {
    return props.image_url
  } else {
    return props.image?.filename
  }
})
</script>

<style lang="scss">
.sb-blog-ad {
  display: block;
  text-decoration: none;
  position: relative;
  width: 100%;
  height: 170px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: $spacing-xs;
  padding: $spacing-xl;
  margin-top: $spacing-sm;
  margin-bottom: $spacing-md;
  cursor: pointer;
  &-image {
    width: 100% !important;
    position: absolute !important;
    height: 170px;
    top: 0;
    left: 0;
    border-radius: $spacing-xs;
  }
  &-link {
    position: relative;
    text-decoration: none;
    padding: $spacing-md;
    h1,
    p {
      color: var(--white);
    }
    &-content {
      &-subtitle {
        font-weight: 400;
      }
    }
  }
  @include mq($mq-sm) {
    width: calc(100% + $spacing-xl);
    margin-left: -$spacing-md;
    margin-top: 0 !important;
    border-radius: 0;
    &-content {
      max-width: calc(100% * 2 / 3);
    }
    h1,
    h2 {
      font-size: pxToRem(15px);
    }
  }
}
</style>
